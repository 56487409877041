import { Link } from 'react-scroll'



function Navbar(props) {

  return (
    <div className="navbackground" >
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="d-flex flex-row justify-content-around">
          <img src="logoheader.svg" id='logoMobile' className="navbar-toggler-icon-toggler d-inline-block align-top ml-5 mt-auto" alt="" style={{ width: "30%" }} />
          <button className=" navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className=" navbar-toggler-icon"></span>
          </button>
        </div>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-center pr-5 ">
            <a className=" px-5 mx-5 mt-5" style={{ width: "30%" }} href="#">
              <img src="logoHeader.svg" class="d-inline-block align-top ml-5 " alt="" style={{ width: "100%" }} /></a>
            <li className="nav-item mx-2 mt-5">
              <Link to="maranata" spy={true} smooth={true} offset={50} duration={500}><a className="nav-link" href="/">Maranata Premium</a></Link>
            </li>

            <li className="nav-item mx-2 mt-5">
              <Link to="linhas" spy={true} smooth={true} offset={50} duration={500}><a className="nav-link" href="/">Nossas linhas</a></Link>
            </li>
            <li className="nav-item mx-2 mt-5">
              <Link to="onde" spy={true} smooth={true} offset={50} duration={500}><a className="nav-link" href="/">De onde Vem</a></Link>
            </li>
            <li className="nav-item mx-2 mt-5">
              <Link to="loja" spy={true} smooth={true} offset={50} duration={500}><a className="nav-link" href="/">Loja</a></Link>
            </li>
            <li className="nav-item mx-2 mt-5">
              <Link to="contato" spy={true} smooth={true} offset={50} duration={500}><a className="nav-link" href="/">Contato</a></Link>
            </li>
          </ul>
        </div>
      </nav>

    </div >
  );
}

export default Navbar;