import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Premium from './Components/Premium';
import Consulta from './Components/Consulta';
import NossaLinha from './Components/NossaLinha';

import React, { useRef } from 'react';
import DeOndeVem from './Components/DeOndeVem';
import Loja from './Loja';


function App() {

  return (
    <div>
      <header>
        <Navbar ></Navbar>
      </header>
      <section id="maranata">
        <Premium></Premium>
      </section>
        <section id="linhas">
          <NossaLinha></NossaLinha>
        </section>
        <section id="onde">
          <DeOndeVem></DeOndeVem>
        </section>
        <section>
          <Loja id="loja"></Loja>
        </section>
        <section id="contato">
          <Consulta></Consulta>
        </section>
      <footer>
        <Footer></Footer>
      </footer>

    </div>
  );
}

export default App;
