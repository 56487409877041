function Consulta() {


  return (
    <div class="fluid-container pt-5" id="backgroundConsulta">
      <div class="row no-gutters reverse" >
        <div class="col-lg ml-5 order-1">
          <div class="menu-image h-100 d-flex align-items-center">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9451.060284174428!2d-37.34762738181035!3d-5.154878890084142!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x93130d6673781e4!2sMaranata%20Salineira%20do%20Brasil!5e0!3m2!1spt-BR!2sbr!4v1608055275119!5m2!1spt-BR!2sbr" width="100%" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>          </div>
        </div>


        <div className='order-2' id='contatoMobile'>
          <div className=" d-flex flex-column" >
            <img className='text-center' src="./Maranata_Logo.svg"></img>
            <div className='px-5 mx-5 text-center'>
              <h4 className=" pt-4 pb-2">Maranata Salineira</h4>
              <p >MARANATA SALINEIRA DO BRASIL LTDA
              CNPJ: 07.851.963/0001-48
              Av. Industrial Dehuel Vieira Diniz, 5000
Mossoró - RN, CEP 59623-310</p>
              <p className="w-50">contato@grupomaranata.com.br
(84) 3422-0100</p>
            </div>
            <div className="d-flex m flex-row justify-content-start" id="iconsConsulta">
              <a href="https://www.linkedin.com/company/salmaranata"><img src="linkedin.svg" style={{ width: "50px", height: "50px", maxHeight: "50px", maxWidth: "50px", marginRight: "20px", alignSelf: "start" }}></img></a>
              <a href="https://www.instagram.com/salmaranata/?hl=pt-br"><img src="insta.svg" style={{ width: "50px", height: "50px", maxHeight: "50px", maxWidth: "50px", marginRight: "20px", alignSelf: "start" }}></img></a>
              <a href="https://api.whatsapp.com/send?phone=558434220100"><img src="wpp.svg" style={{ width: "50px", height: "50px", maxHeight: "50px", maxWidth: "50px", marginRight: "20px", alignSelf: "start" }}></img></a>

            </div>
          </div>
        </div>




        <div class=" ml-5 col-lg d-flex flex-column align-items-center order-2" id='contatoWeb'>
          <div class="menu-text flex-grow-1 py-5 pr-5" >
            <div className="ml-auto w-100 consultaP " >
              <div className=" ml-auto py-5 mt-5 px-5" >
                <img src="./logoCirculo.svg"></img>
                <h4 className="w-75 pt-4 pb-2">Maranata Salineira</h4>
                <p className="w-75">MARANATA SALINEIRA DO BRASIL LTDA
                CNPJ: 07.851.963/0001-48
                Av. Industrial Dehuel Vieira Diniz, 5000
Mossoró - RN, CEP 59623-310</p>
                <p className="w-50">contato@grupomaranata.com.br
(84) 3422-0100</p>
                <div className="d-flex m flex-row justify-content-start" id="iconsConsulta">
                  <a href="https://www.linkedin.com/company/salmaranata"><img src="linkedin.svg" style={{ width: "50px", height: "50px", maxHeight: "50px", maxWidth: "50px", marginRight: "20px", alignSelf: "start" }}></img></a>
                  <a href="https://www.instagram.com/salmaranata/?hl=pt-br"><img src="insta.svg" style={{ width: "50px", height: "50px", maxHeight: "50px", maxWidth: "50px", marginRight: "20px", alignSelf: "start" }}></img></a>
                  <a href="https://api.whatsapp.com/send?phone=558434220100"><img src="wpp.svg" style={{ width: "50px", height: "50px", maxHeight: "50px", maxWidth: "50px", marginRight: "20px", alignSelf: "start" }}></img></a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Consulta;
