

export default function DeOndeVem() {
    return (
        <div className="backgroundDeOndeVem">
            <div className="d-flex flex-column justify-content-center" id='fundoAzul'>
                <img style={{ width: '100%' }} src="./dunaMobile.svg"></img>
                <div className='w-100'>

                    <img style={{height: '12vw', textAlign: "center", marginLeft: 'auto', marginRight: 'auto'}} src='./6.svg'></img>
                </div>

                <div className="d-flex flex-row" style={{ marginTop: "12vw" }}>

                    <div className="offset-1 col-lg-4" className='Salina'>
                        <img id='deondeWeb' src='./3.svg'></img>
                        <h4>DA SALINA PARA SEUS CLIENTES</h4>
                        <p >Com 100% de sal marinho, o sal União é produzido através da captação da água do oceano atlântico localizada no paralelo 5° sul. Na perfeita combinação entre uma das correntes marítimas mais pura com o clima semiárido de ventos alísios constante e solo argiloso impermeável.</p>
                    </div>

                </div>
            </div>

        </div>
    )
}