
export default function Loja(){
    return(
        <div className="d-flex flex-row align-items-center flex-wrap" style={{backgroundColor: "#e5f1f4"}} id="loja">
            <img id="lojaWeb" className="ml-auto mr-auto" style={{ width: "80vw", height: "50vw"}} src="./loja.svg"></img>
            <div className='d-flex flex-column w-100' id='lojaMobile'>
                <img style={{height: '40vw'}} id='lojaTitle' src='./4.svg'></img>
                <p>Além de supermercados e lojas especializadas em todo o país você pode encomendar todos os produtos que vão fazer a diferença na sua cozinha e receber em casa.</p>
                <img style={{height: '110vw'}} src='./lojaMobile.svg'></img>
            </div>
        </div>
    )
}