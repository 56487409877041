

function Footer() {
    return (
        <div className="d-flex flex-row footer justify-content-center Footer">
            <a style={{height: '2vw'}} href="https://aagenciadigital.com/"><img src="agencia.png"  style={{height: '100%'}}></img></a>
        </div>
    );
}

export default Footer;
