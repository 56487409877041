
function NossaLinha() {
    return (
        <div className="fluid-container mt-5 pt-5" id="Linhas">
            <div className="d-flex flex-column" id='mycarrousel'>
                <div className="d-flex flex-row justify-content-center" id="nossasLinhas"  style={{ width: '100%'}}>
                   <img className='text-center w-100'  src="./2.svg"></img>

                </div>
                <div id="carouselExampleControls" className="carousel slide pt-5" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <a href='http://soyparrillero.maranatapremium.salmaranata.com.br/'><img src="./produto1.svg" className="d-block w-100 carrouselImage" alt="..." /></a>
                        </div>
                        <div className="carousel-item">
                            <a href='https://artesanal.maranatapremium.salmaranata.com.br/'><img src="./produto2.svg" className="d-block w-100 carrouselImage" alt="..." /></a>
                        </div>
                        <div className="carousel-item">
                            <a href='https://origens.maranatapremium.salmaranata.com.br/'> <img src="./produto3.svg" className="d-block w-100 carrouselImage" alt="..." /> </a>
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <img src="./setaback.svg"></img>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <img src="./setafront.svg"></img>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default NossaLinha;
