
function Premium() {
  return (
    <div className="fluid-container " id="Maranata" style={{ backgroundColor: "#e5f1f4" }}>
      <div className="d-flex flex-row flex-wrap justify-content-center">
        <img id='Sabor' src='1.svg'  className='mx-5'></img>

        <div className='col-lg-4' id="textos">
          <p className="mt-2 pb-4" >Quem gosta de cozinhar de verdade sabe a diferença entre um sal comum e um sal premium, por isso trouxemos muito mais sabor, saúde e qualidade para agradar os clientes mais exigentes.</p>
          <p className="mt-2 ">E tudo isso com menos processos, desde a coleta até o transporte, tudo com o selo de qualidade da Maranata Salineira, que está há mais de 30 anos produzindo o melhor do sal..</p>

        </div>

      </div>
      <div className="menu-image  d-flex flex-row align-items-center reverse">
        <img src="./selo.svg" className="img-fluid" id="seloImage" alt="menu" />
      </div>
      <div className="menu-image  d-flex flex-row align-items-center reverse">
        <img src="./maranataImagemMobile.svg" className="img-fluid" id="maranataMobile" alt="menu" />
      </div>
    </div>
  );
}

export default Premium;
